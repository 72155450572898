import { render, staticRenderFns } from "./dynamicFormBuilder.vue?vue&type=template&id=2008b899&scoped=true"
import script from "./dynamicFormBuilder.vue?vue&type=script&lang=js"
export * from "./dynamicFormBuilder.vue?vue&type=script&lang=js"
import style0 from "./dynamicFormBuilder.vue?vue&type=style&index=0&id=2008b899&prod&lang=scss"
import style1 from "./dynamicFormBuilder.vue?vue&type=style&index=1&id=2008b899&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2008b899",
  null
  
)

export default component.exports