<template>
  <div
    class="section flex flex-1 flex-col w-full overflow-hidden form sm:px-0"
    :class="{'section--preview-form': previewForm}"
  >
    <!-- <header
      class="sm:px-4 lg:px-6 md:px-5 text-center md:text-left capitalize py-3 border-0 text-s md:text-lg font-medium text-base-content tracking-wider border-b border-solid border-gray-50 bg-gray-50"
      v-if="gapErrors && gapErrors.gapErrorsView" 
    >
      {{ currentSection.section_name }}
    </header> -->
    <div
      class="h-full scroll-bar overflow-y-auto px-2 flex flex-col align-stretch gap-8"
    >
      <div
        v-for="(section,sIndex) in renderedSections"
        :key="section.section_id"
        class="section__item relative"
        :class="{'dynamicSection': previewForm}"
      >
        <h2
          v-if="previewForm"
          class="text-xl capitalize font-semibold text-base-content pb-2 mb-2 border-b border-solid check"
        >
          {{ section.section_name }}
        </h2>
        <span
          v-if="section.section_helper_text"
          class="text-base-content-300 tracking-wide px-2 mb-4 block"
          v-text="section.section_helper_text"
        />
        <!-- <transition-group tag="div" name="transition-list"> -->
          <div class="groups flex flex-col items-stretch py-5" :class="{'mobileHeadear' : !previewForm, 'pb-0' : previewForm}">
            <div
              v-for="group in getGroupedEntities(section)"
              :key="group.id"
              class="group flex flex-col items-stretch gap-4"
              >
              <!-- :class="{'bg-gray-100  p-5' :  !!group.list.some(grp => grp.entity.value) }" -->
              <div
                v-if="showGroup(group)"
                class="group__header flex items-center justify-between"
              >
                <h3 class="header__name text-lg blockName">
                  {{ group.list[0].entity.field_name || group.list[0].entity.block_name }}
                </h3>
                <div
                  v-if="showCollapse(group.list)"
                  class="flex gap-2 items-center cursor-pointer print--none expandBtn"
                  @click="collapseGroup(group)"
                  :class="{ hideLabel:previewForm } "
                >
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      class="text-primary text-lg transition-all"
                      :class="{ 'transform rotate-180': allGroupCollapsed }"
                    />
                    <span class="text-base-content text-sm flex items-center font-semibold">
                      {{ allGroupCollapsed ? 'Expand All' : 'Collapse All'}}
                    </span>
                    <!-- <span class="text-base-content text-sm flex
                       items-center gap-2 cursor-pointer" v-else @click="expandSections"><font-awesome-icon icon="chevron-circle-down" class="text-base-content cursor-pointer text-lg" /> Expand All </span> -->
                </div>
              </div>
              <div class="group__entities flex flex-col items-stretch">
                <div
                    v-if="group?.list[0]?.entity.form_entity_type === 'BLOCK'"
                    :key="group?.list[0]?.entity.block_fe_id + '_name'"
                    class=""
                    :class="`block-gaps-container ${group?.list[0]?.entity.block_fe_id}_name`"
                  >
                    <portal-target v-if="previewForm && !showGroup(group)" :name="`${group?.list[0]?.entity.block_fe_id}_name-preview`" />
                    <portal-target v-else :name="`${group?.list[0]?.entity.block_fe_id}_name`" />
                  </div>
                <div
                    v-if="group?.list[0]?.entity.form_entity_type === 'BLOCK'"
                    :key="group?.list[0]?.entity.block_fe_id + '_gaps'"
                    :class="`block-gaps-container stickContainer ${group?.list[0]?.entity.block_fe_id}_gaps filter top-2  drop-shadow-lg`"
                  >
                    <portal-target v-if="previewForm && !showGroup(group)" :name="`${group?.list[0]?.entity.block_fe_id}_gaps-preview`" />
                    <portal-target v-else :name="`${group?.list[0]?.entity.block_fe_id}_gaps`" />
                  </div>
                <div
                  v-for="(el, posInGroup) in group.list"
                  :key="el.idx"
                >
                  <!-- Since two form-builders (normal and preview) can be shown at the same time, we must use different portals -->
                  <!-- <div
                    v-if="el.entity.form_entity_type === 'BLOCK'"
                    :key="el.entity.block_fe_id + '_name'"
                    class=""
                    :class="`block-gaps-container ${el.entity.block_fe_id}_name`"
                  >
                    <portal-target v-if="previewForm && !showGroup(group)" :name="`${el.entity.block_fe_id}_name-preview`" />
                    <portal-target v-else :name="`${el.entity.block_fe_id}_name`" />
                  </div> -->

                  <!-- <div
                    v-if="el.entity.form_entity_type === 'BLOCK'"
                    :key="el.entity.block_fe_id + '_gaps'"
                    :class="`block-gaps-container stickContainer ${el.entity.block_fe_id}_gaps filter top-2  drop-shadow-lg`"
                  >
                    <portal-target v-if="previewForm && !showGroup(group)" :name="`${el.entity.block_fe_id}_gaps-preview`" />
                    <portal-target v-else :name="`${el.entity.block_fe_id}_gaps`" />
                  </div> -->
                  <div>
                  <portal-target v-if="previewForm && !showGroup(group)" :name="`${el.entity.block_fe_id}_gaps_block-preview`" />
                    <portal-target v-else :name="`${el.entity.block_fe_id}_gaps_block`" />
                  </div>
                  <fieldset
                    class="border-0 outline-none transition-list-item"
                    :key="el.entity[conf[el.entity.form_entity_type].id] ||
                        el.entity.multi_id ||
                        el.entity._id"
                    :class="{dynamicBlockFieldSet: previewForm}"
                  >
                    <template v-if="el.entity.form_entity_type === 'BLOCK'">
                      <Block
                        :blockData="el.entity"
                        :totalData="renderedSections"
                        :section="section"
                        :path="[
                          { field: 'section_id', value: section.section_id },
                          { field: 'block_fe_id', value: el.entity.block_fe_id },
                        ]"
                        :isMulti="checkIfMulti(el.entity, getEntitiesList(section))"
                        :validations="currentValidations ? currentValidations[el.idx].entities : null"
                        :parentValidations="currentValidations"
                        :previewMode="previewMode"
                        :previewForm="previewForm"
                        :grouped="showGroup(group)"
                        :posInGroup="posInGroup"
                        :ref="group.id"
                        :scrollToGap="scrollToGap"
                        @disableScrollToGap="scrollToGap = false"
                        @collapseClicked="collapseClick(group)"
                        @expandClicked="expandClicked"
                        :class="{'blockCollaps': posInGroup === 0, dynamicBlock: previewForm}"
                        :sIndex="sIndex"
                        @collapseChanged="collapseChanged"
                        :defaultCollapseState = "blockCollapseData[el.entity.block_fe_id]"
                        :gapErrorMsg="gapErrorMsg"
                        :formAlreadySubmitted="formAlreadySubmitted"
                        @checkCheckboxId="checkCheckboxId"
                        :checkBoxId="checkBoxId"
                        @emtpyBlockFieldId="emtpyBlockFieldId"
                        :findErrors="findErrors"
                      />
                    </template>
                    <template v-else-if="el.entity.form_entity_type === 'LAYOUT'">
                      <!-- // layout type entity  -->
                      <Layout
                        :layoutData="el.entity"
                        :path="[
                          { field: 'section_id', value: section.section_id },
                          { field: 'layout_id', value: el.entity.layout_id },
                        ]"
                        :validations="currentValidations ? currentValidations[el.idx].entities : null"
                        :previewMode="previewMode"
                        :previewForm="previewForm"
                        class="my-4"
                      />
                    </template>
                    <template v-else-if="el.entity.form_entity_type === 'FIELD'">
                      <!-- // Form fields inputs and texts  -->
                      <FormField
                        
                        :ref="getFieldRef(el.entity)"
                        :fieldData="el.entity"
                        :parentData="section"
                        :userData="userData"
                        :path="[
                          { field: 'section_id', value: section.section_id },
                          { field: 'field_fe_id', value: el.entity.field_fe_id },
                        ]"
                        :isMulti="checkIfMulti(el.entity, getEntitiesList(section))"
                        :validations="currentValidations ? currentValidations[el.idx] : null"
                        :previewMode="previewMode"
                        :previewForm="previewForm"
                        class="my-4"
                        :class="{dynamicFields: previewForm}"
                        :notInsideBlock="true"
                        :formAlreadySubmitted="formAlreadySubmitted"
                      />
                    </template>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        <!-- </transition-group> -->
      </div>
      <!-- TODO remove when custom sections are ready: -->
      <!-- <template v-else-if="currentSection.type === 'welcome'">
        <div
          class="border border-gray-200 rounded shadow bg-white p-4"
          v-html="currentSection.content"
        />
      </template>
      <template v-else-if="currentSection.type === 'personal_details'">
        <ul class="personal-details flex flex-col items-start gap-2 w-full">
          <li
            class="personal-details__item text-lg w-full"
            v-for="prop of currentSection.data"
            :key="prop.field_id"
          >
            <PersonalDetailsField
              :fieldData="prop"
              :validations="currentValidations[prop.field_id]"
            />
          </li>
        </ul>
      </template> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Layout from "./layoutForm.vue";
import FormField from "./formField.vue";
import Block from "./formBlock.vue";
// TODO remove when custom sections are ready:
// import PersonalDetailsField from "./personalDetailsField.vue";
import { checkIfMulti, conf } from "../utils";
// import GapError from "./form-components/gap-error.vue";
import sections from "../store";
import PortalVue from 'portal-vue';
import fieldTypes from "../utils/field-types";

Vue.use(PortalVue);

export default {
  props: {
    // currentSection: {
    //   type: Object,
    //   require: true,
    // },
    // used for validation, so it's source must be origin sections_data:
    currentSectionIndex: {
      type: Number,
      // require: true,
    },
    validations: {
      type: Object,
      default: () => null,
    },
    gapErrors: {
        type: Object,
        default:()=> {}
    },
    // is used to preview form before creating it in an admin panel:
    previewMode: {
      type: Boolean,
      default: false
    },
    // is used to prview filled form before its submitting in a modal:
    previewForm:{
        type: Boolean,
        default: false
    },
    userData: {
      type: Object,
      default:()=> {}
    },
    gapErrorMsg: String,
    formAlreadySubmitted: {
      type: Boolean,
    },
    findErrors:{
      type:Object
    }
  },
  components: {
    Layout,
    FormField,
    Block,
    // GapError,
    // TODO remove when custom sections are ready:
    // PersonalDetailsField,
  },
  data() {
    return {
      fieldTypes,
      checkIfMulti,
      conf,
      expanded: true,
      collapsedGroups: {},
      scrollToGap: true,
      allGroupCollapsed: false,
      sectionData: {},
      blockCollapseData: {},
      checkBoxId: "",
      sectionDivHeight:"",
      isMobile:false
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.currentSection.sectionHide)
        this.$emit('changeHiddenSection')
    });

  },
  computed: {
    currentSection() {
      if (typeof this.currentSectionIndex === 'undefined') return null;
      return this.sections_data[this.currentSectionIndex];
    },
    sections_data() {
      return sections.list;
    },
    renderedSections() {
      if (!this.previewForm) return [this.currentSection];
      return this.sections_data;
    },
    // getEntitiesList() {
    //   let block_entities = this.currentSection.entities || [];
    //   // block_entities.forEach(e=>{
    //   //     e.block_fe_id = e.block_id
    //   //     e.serial = 1
    //   // })

    //   // let obj = {}
    //   // const makeLastBlock = (block,isLast) => ({...block, isLast})
    //   // let currentBlockId = ''
    //   return block_entities;
    // },
    currentValidations() {
      return this.validations?.entities || this.validations;
    },




      // dependencies() {
      //     return store.dependencies;
      // },
      // // * Here, the related field is the field-influencer which value define if the current field is shown
      // isRelated() {
      //     return Boolean((this.fieldData.pathString in this.dependencies) && this.fieldData.condition);
      // },
      // dependencyData() {
      //     return this.dependencies[this.fieldData.pathString];
      // },
      // // related field is a field which value determine if the current field is shown:
      // relatedField() {
      //     return this.dependencyData?.related_field;
      // },
      // isRelatedShown() {
      //     // if field isn't dependent or related field isn't dependent we don't need to check this:
      //     if (!this.isRelated || !this.dependencies[this.relatedField.pathString]) return true;
      //     return !this.dependencies[this.relatedField.pathString].hidden;
      // },
      
  },

  mounted() {
    this.calculateMarginTop();
    window.addEventListener('resize', this.calculateMarginTop);
  },
  methods: {
    showCollapse(section){
      let showCollapseItem = true;
      for(let i = 0;i<section.length;i++ ) { 
         let sectionData = section[i].entity.entities;
         sectionData.forEach((item) => { 
            if(item.field_type==='Date Range (Timeline)'){
              showCollapseItem = false
            }
         })
      }
      return showCollapseItem;
    },
    collapseChanged(id,value){
      this.blockCollapseData[id]=value
    },
    calculateMarginTop() {
      const element1 = document.querySelector('.mobile_section_header')
      const element2 = document.querySelector('.mobileHeadear')
      const { height } = element1.getBoundingClientRect();
      element2.style.marginTop = `${height}px`;
    },
    getFieldRef(field) {
      if (this.getFieldConfig(field).customTemplate)
        return `form-field-${this.getFieldConfig(field).customTemplate}`
      else return `form-field-${this.getFieldType(field)}`
    },
    getFieldConfig(field) {
        return this.fieldTypes[this.getFieldType(field)] || this.fieldTypes[this.getFieldBaseType(field)];
    },
    getFieldType(field) {
        return field?.field_type?.toLowerCase();
    },
    getFieldBaseType(field) {
        return field?.field_base_type?.toLowerCase();
    },
    onExpanded() {
      this.expanded = !this.expanded;
    },
    getEntitiesList(section) {
      let entities = section.entities || [];
      this.sectionData = section.entities;
      // sort by keys 
      // return block_entities.sort((a,b) => b.serial - a.serial) 
      return entities;
    },
    getGroupedEntities(section) {
      // blocks/fields in group have the same block_id/field_id. Use appropriate for each entity type:
      const groupId = {
        FIELD: 'field_id',
        BLOCK: 'block_id',
      }
      const groupedList = section.entities.reduce((result, curr, idx) => {
        // idx is used to find a validation reference
        const group = result.find(gr => gr.id === curr[groupId[curr.form_entity_type]]);
        if (group) group.list.push({entity: curr, idx});
        else result.push({ id: curr[groupId[curr.form_entity_type]], list: [{entity: curr, idx}] })
        return result;
      }, []);
      // check group visibility:
      const result = groupedList.map((group) => {
        return Object.assign(group, {
          visible: !!group.list[0]?.entity?.visible,
        });
      });
      return result;
    },
    sectionType(section) {
      return section.type;
    },
    checkCheckboxId(val){
      this.checkBoxId = val;
    },
    emtpyBlockFieldId(val){
      if(this.checkBoxId === val)
        this.checkBoxId = "";
    },
    showGroup(group) {
      return group.list.length > 1 && group.visible;
    },
    collapseGroup(group) {
      this.collapseClick(group)
      const newValue = !this.collapsedGroups[group.id];
      this.$set(this.collapsedGroups, group.id, newValue);
      if (Array.isArray(this.$refs[group.id])) {
        this.$refs[group.id].forEach(block => {
          block.toggleCollapse(newValue);
        });
      } else {
        this.$refs[group.id].toggleCollapse(newValue)
      }
    },
    collapseClick(group){
      let arr = this.$refs[group.id].filter(block => block.isCollapsed === true);
      this.allGroupCollapsed = arr.length ? true : false;
    },
    expandClicked(){
      this.allGroupCollapsed = false;
    },
    //added this method to scroll to gap timeline if exist on saveForm. 
    scrollToGapOnSave() {
      this.$nextTick(() => {
        for (let key in this.$refs) {
          if (this.$refs[key]) {
            this.$refs[key]?.forEach((method) => {
              if(typeof method?.scrollToGaps != "undefined" && typeof method?.isInvalidGap != "undefined" && method?.isInvalidGap()) {
                  method?.scrollToGaps();
              }
            })
          }
        }
      });
    }

    // isShown() {
    //   if (!this.fieldData.visible) return false;
    //   if (this.isRelated) {
    //       return this.relatedField?.value === this.dependencyData.condition.dependence_value && this.isRelatedShown;
    //   } else return true;
    // },


  },
};
</script>
<style lang="scss">
.section {
  &--preview-form & {
    &__item {
      @apply bg-white rounded-xl p-5;
      // &:not(:first-child) {
      //   @apply pt-7;
      // }
      // &:not(:last-child) {
      //   @apply pb-7 border-b border-solid;
      // }
    }
  }
}
</style>
<style lang="scss" scoped>
.block-gaps-container {
  z-index: 1;
  // position: sticky;
  top: 0;
}

.transition-list-item {
  transition: all .4s;
  ::v-deep {
    .block-form {
      transition: all 2s;
      transition-delay: .75s;
    }
  }
}
.transition-list-enter, .transition-list-leave-to {
  opacity: 0;
}
.transition-list-enter {
  ::v-deep {
    .block-form {
      box-shadow: 0 0 25px rgba(75, 135, 192, 0.342);
    }
  }
}
.transition-list-leave-active {
  position: absolute;
  transition: opacity .3s;
  width: 100%;
}
.hideLabel{
  display:none
}

.group__entities {
  margin-bottom: 12px;
}

@media screen and (min-width: 300px) and (max-width: 768px){
  .dynamicSection{
    padding: 1rem;
    padding-bottom: 0 !important;
  }
  .dynamicBlock{
    margin-bottom: 4%;
  }
  .dynamicBlock,.dynamicFields{
    width:100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dynamicBlockFieldSet{
    margin-bottom: 5%;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 359px){

  .dynamicBlockFieldSet{
    margin-left: -24px;
  }

  .dynamicBlock,.dynamicFields{
    width:218px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 389px){
  .dynamicBlockFieldSet{
    margin-left: -13px;
  }

  .dynamicBlock,.dynamicFields{
    width:260px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 413px){
  .dynamicBlockFieldSet{
    margin-left: -5px;
  }

  .dynamicBlock,.dynamicFields{
    width:290px !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 500px){
  .dynamicBlockFieldSet{
    margin-left: -5px;
  }

  .dynamicBlock,.dynamicFields{
    width:290px !important;
  }
}
.blockName {
    display: none;
  }
.expandBtn {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 0px 11px -12px 0px;
  }
@media screen and (min-width: 300px) and (max-width: 500px){
  .blockName {
    display: none;
  }
  .expandBtn {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 0px 11px -12px 0px;
  }
  .group__entities {
    margin-bottom: 12px;
  }
}

.stickContainer {
  position:sticky;
  top: 0;
  z-index: 2;
}
// @media screen and (min-width:844px)
// {
//   .stickContainer {
//   position:sticky;
//   top: 0;
// }
// }

@media screen and (max-width: 1080px) {
  .mobileHeadear {
    // margin-top: 65px !important;
  }
}

  .blockCollaps {
    margin-top: 0;
  }

::v-deep {
  .multiselect__tags{
    height: 3rem !important;
    border-radius: 13px !important;
  }
  .multiselect__select{
    background-color: transparent !important;
  }
}

</style>
